import React, { useEffect, useRef, useState } from 'react';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Checkbox from '@pages/DesignSystem/Checkbox/Checkbox';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import Tooltip from '@pages/DesignSystem/Tool-Tip';
import { OTP_SOURCE_TYPE } from '@constants/app-constants';
import { useDispatch, useSelector } from 'react-redux';
import mfaActions from '../mfaActions';
import { MFA_CONSTANTS } from '../constants';

export const OtpValidation = ({ userEnteredNumber, setAccountLock, setMfaVerified, setResendOtp, setSetupPage, setOtpValidation }) => {
	const [otp, setOtp] = useState(Array(6).fill(''));
	const [validation, setValidation] = useState(false);
	const [rememberDevice, setRememberDevice] = useState(false);
	const [attempted, setAttempted] = useState(0);
	const [seconds, setSeconds] = useState(30);
	const inputRefs = useRef(Array(6).fill(null));
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);
	const mfaMode = auth.mfa?.mode || '';

	useEffect(() => {
		const { status, remainingAttemptsCount } = auth.otp || {};
		const { mfa: mfaError } = auth.error || {};
		if (status) {
			if (status === MFA_CONSTANTS.otpStatusVerify.WRONG_OTP) {
				if (remainingAttemptsCount) {
					setAttempted(remainingAttemptsCount);
				}
				setValidation(true);
			} else if (status === MFA_CONSTANTS.otpStatusVerify.LOCKED) {
				setValidation(false);
				setAccountLock(true);
			} else if (status === MFA_CONSTANTS.otpStatusVerify.VERIFIED) {
				setValidation(false);
				setAccountLock(false);
				setMfaVerified(true);
			}
		} else if (mfaError) {
			setOtpValidation(true);
			setValidation(true);
		}
	}, [auth.otp, auth.error]);

	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [seconds]);
	useEffect(() => {
		inputRefs.current = inputRefs.current.map((ref, index) => ref || createRef(index));
	}, []);

	// To check whether the mfa mode is SETUP
	const isSetupMode = () => {
		if (auth.mfa.mode === MFA_CONSTANTS.mfa.SETUP) {
			return true;
		}
		return false;
	};

	const createRef = (index) => {
		inputRefs.current[index] = React.createRef();
		return inputRefs.current[index];
	};

	const handleInput = (e, index) => {
		const value = e.target?.value;
		if (!isNaN(value)) {
			setOtp([...otp.slice(0, index), value, ...otp.slice(index + 1)]);
			if (value && inputRefs.current[index + 1]) {
				inputRefs.current[index + 1].current.focus();
			}
			if (value && !inputRefs.current[index + 1]) {
				inputRefs.current[index].current.blur();
			}
			// Move focus to the previous input if the current input is empty and backspace is pressed
			if (value === '') {
				// Using backspace key to remove input
				if (e.nativeEvent.inputType === 'deleteContentBackward' && inputRefs.current[index - 1]) {
					inputRefs.current[index - 1].current.focus();
				// Using delete key to remove input
				} else if (e.nativeEvent.inputType === 'deleteContentForward' && inputRefs.current[index + 1]) {
					inputRefs.current[index + 1].current.focus();
				}
				setValidation(false);
			}
		}
	};
	const handlePaste = (e) => {
		if (!isNaN(e.clipboardData.getData('text'))) {
			setOtp(e.clipboardData.getData('text').substring(0, 6).split(''));
		}
	};

	const handleVerifyOtp = () => {
		const body = {
			otp: otp.join(''),
			type: OTP_SOURCE_TYPE.sms,
			rememberMe: rememberDevice,
		};
		if (isSetupMode()) {
			// If the mfa mode is setup then we send user's phone number in body
			body.phoneNumber = userEnteredNumber;
		}
		dispatch(mfaActions.creators.verifyMfa(body));
	};

	const handleResendOtp = () => {
		const body = {
			reason: MFA_CONSTANTS.otpReason.mfa,
		};
		if (isSetupMode()) {
			// If the mfa mode is setup then we send user's phone number in body
			body.phoneNumber = userEnteredNumber;
		}
		dispatch(mfaActions.creators.sendOtp(body));
		setOtp(new Array(6).fill(''));
		setValidation(false);
		setSeconds(30);
		setResendOtp(true);
	};

	const validOTP = () => {
		if (otp.join('').length === 6 && !validation) {
			return true;
		}
		return false;
	};

	function encryptMobileNumber(number = '') {
		const firstThreeDigits = number?.slice(0, 4); // taking '+' too in digit like this +971
		const lastTwoDigits = number?.slice(-2);
		// Here -5 is the first three digit and last two digit count
		const middleDigits = '*'.repeat(number.length - 5); // Replace middle digits with asterisks

		return `${firstThreeDigits}${middleDigits}${lastTwoDigits}`;
	}

	const otpMsgHeader = () => {
		const mfaSetupMode = mfaMode === MFA_CONSTANTS.mfa.SETUP;
		const mfaRequiredMode = mfaMode === MFA_CONSTANTS.mfa.REQUIRED;

		const handleChangeNumber = () => {
			setSetupPage(true);
			setOtpValidation(false);
			setValidation(false);
		};

		if (mfaSetupMode) {
			return (
				<div onClick={handleChangeNumber} className="change_number_text">
					<ETypo color="#0E5274" b1>
						Change Number
					</ETypo>
				</div>
			);
		} else if (mfaRequiredMode) {
			return (
				<Tooltip
					direction="up"
					className="otp-info-icon"
					content={<span className="tooltip-text">You can update your Number from the Profile section</span>}>
					<img src={not_focus_icons.infoLight} alt="info-icon" />
				</Tooltip>
			);
		}
		return null;
	};

	const validationErrorMsg = () => {
		if (validation && attempted) {
			return (
				<ETypo b3 className="error-msg">
					{`Incorrect OTP! ${attempted} attempts remaining`}
				</ETypo>
			);
		} else if (validation && !attempted) {
			return (
				<ETypo b3 className="error-msg">
					Something went wrong
				</ETypo>
			);
		}
		return null;
	};

	return (
		<div className="otp-container">
			<div className="otp-header">
				<div className="otp-title">
					<ETypo h3 bold color="#181919">
						Enter the OTP to verify your account
					</ETypo>
				</div>
				<div className={`otp-description otp-description-${mfaMode?.toLowerCase()}`}>
					<ETypo className="otp-msg" b1 light>
						An OTP has been sent to {encryptMobileNumber(userEnteredNumber)}
					</ETypo>
					{otpMsgHeader()}
				</div>
			</div>
			<div className="otp-input-container">
				<div className="otp-input-wrapper">
					<div className="otp-input">
						{otp.map((digit, index) => (
							<input
								className={`input-field ${digit !== '' ? 'input-field-value' : 'border'} ${validation ? 'error-input' : ''}`}
								key={index}
								inputmode='numeric'
								type="text"
								pattern="[0-9]*"
								autoComplete="off"
								maxLength="1"
								name={`otp${index}`}
								value={digit}
								autoFocus={index === 0}
								onChange={(e) => handleInput(e, index)}
								onPaste={handlePaste}
								ref={inputRefs.current[index]}
							/>
						))}
					</div>
					{validationErrorMsg()}
				</div>
				<div className="remember-device-checkbox">
					<Checkbox labelPadding="10px 10px 10px 0px" onChange={() => setRememberDevice((prev) => !prev)} checked={rememberDevice} />
					<ETypo b2 light color="#484b52"> 
						Remember device for 30 Days
					</ETypo>
				</div>
			</div>
			<div className="otp-validation">
				<ETypo b1 color="#313338" onClick={seconds === 0 ? handleResendOtp : undefined}>
					<span className={seconds === 0 ? 'resend-otp-text' : ''}>Resend OTP</span>&nbsp;
					{seconds > 0 && <span>{`in ${seconds}s`}</span>}
				</ETypo>
				<span className="verify-btn">
					<EdenredButtons
						loading={auth?.otp?.inProgress}
						children={'Verify OTP'}
						btnType={validOTP() ? 'primary' : 'secondary'}
						className={validOTP() ? '' : 'verify-button'}
						onClick={validOTP() ? handleVerifyOtp : undefined}
					/>
				</span>
			</div>
		</div>
	);
};
