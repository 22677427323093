import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SetUpMfa from './SetUpMfa';
import { OtpValidation } from './OtpValidation';
import AccountLocked from './AccountLocked';
import { MFA_CONSTANTS } from '../constants';
import AppLoader from '@sharedComponent/app-loader';
import { CorporateService } from 'src/services';
import { SITEMAP } from 'src/routes/sitemap';
import { message } from 'antd';
import { API_STATUS_CODES } from '@constants/app-constants';
import { COOKIE_CONSTANTS, settingCookie } from 'src/helpers/cookies';
import { useCallEmployeeVerify } from 'src/hooks';

const MfaContainer = () => {
	const auth = useSelector((state) => state.auth);
	const [initialSetup, setInitialSetup] = useState(false);
	const [accountLock, setAccountLock] = useState(false);
	const [otpValidation, setOtpValidation] = useState(false);
	const [mfaVerified, setMfaVerified] = useState(false);
	const [otpSent, setOtpSent] = useState(false);
	const [resendOtp, setResendOtp] = useState(false);
	const history = useHistory();
	const [userEnteredNumber, setUserEnteredNumber] = useState('');
	const location = useLocation();
	const [setupPage, setSetupPage] = useState(false);
	const { callEmployeeVerifyCheck } = useCallEmployeeVerify();

	useEffect(() => {
		if (mfaVerified) {
			resetMfa();
			function redirectToTermsAndConditions() {
				history.replace({ pathname: SITEMAP.termsAndConditions.index, state: { user: location?.state?.user } });
			}

			async function checkCbdClient() {
				try {
					const corporateService = new CorporateService();
					const res = await corporateService.checkCbdClient();
					if (res.data?.StatusCode === API_STATUS_CODES.INTERNAL_ERROR) {
						throw new Error('Something went wrong');
					}
					const { data } = res.data || {};
					// If we get data from the response and if it's a cbd client and the client hasn't accepted TC we redirect to TC page
					if (data && data?.isCbdClient && !data?.isCbdClientAcceptedTc) {
						settingCookie(COOKIE_CONSTANTS.cbdClient.tc, 'true');
						redirectToTermsAndConditions();
					} else {
						callEmployeeVerifyCheck();
					}
				} catch (error) {
					if (error?.message) {
						message.error(error?.message);
					}
					console.error(error, 'cbd client api');
					callEmployeeVerifyCheck();
				}
			}
			checkCbdClient();
		}
	}, [mfaVerified]);

	useEffect(() => {
		const otpSuccess = auth.otp.sent; // OTP sent success
		const otpStatus = auth.otp.status; // OTP status
		const otpInProgress = auth.otp.inProgress; // OTP in progress
		const otpLoading = auth.otp.loading; // OTP in progress
		const { mode, phoneNumber } = auth.mfa;
		const mfaRequired = mode === MFA_CONSTANTS.mfa.REQUIRED;
		const mfaInitialSetup = mode === MFA_CONSTANTS.mfa.SETUP;
		const mfaLocked = mode === MFA_CONSTANTS.mfa.LOCKED;
		const OtpLocked = otpStatus === MFA_CONSTANTS.otpStatusVerify.LOCKED;
		const WrongOtp = otpStatus === MFA_CONSTANTS.otpStatusVerify.WRONG_OTP;
		const mfaError = auth.error?.mfa;

		if (mfaInitialSetup && !otpInProgress && !otpSuccess && !otpValidation) {
			setInitialSetup(true);
		}
		if (resendOtp) {
			setInitialSetup(false);
			setOtpValidation(true);
		}
		if (otpInProgress || WrongOtp || (otpSuccess && !mfaRequired)) {
			setInitialSetup(false);
			setOtpValidation(true);
		}
		if (mfaRequired && !accountLock && !OtpLocked) {
			setInitialSetup(false);
			setUserEnteredNumber(phoneNumber);
			setOtpValidation(true);
		}
		if (mfaLocked || accountLock || OtpLocked) {
			setAccountLock(true);
		}
		// If mfa has any error while sending otp or verify otp need to stay on same screen
		if (mfaError && (!otpInProgress || !otpLoading)) {
			// if the mode is from REQUIRED then staying on otp validation screen
			if (mfaRequired) {
				setInitialSetup(false);
				setOtpValidation(true);
			} else if (mfaInitialSetup) {
				// if the mode is from SETUP and not on phone number set screen then staying on otp validation screen
				if (!initialSetup) {
					setInitialSetup(false);
					setOtpValidation(true);
				}
			}
		}
		return () => {
			resetMfa();
		};
	}, [auth.mfa, auth.otp, auth.error, accountLock, otpSent]);

	function resetMfa() {
		setInitialSetup(false);
		setOtpValidation(false);
		setAccountLock(false);
		setResendOtp(false);
		setSetupPage(false);
	}

	return (
		<>
			{(setupPage || initialSetup) && !accountLock && !otpValidation ? (
				<SetUpMfa {...{ setUserEnteredNumber, setOtpSent }} />
			) : !initialSetup && accountLock && !otpValidation ? (
				<AccountLocked />
			) : !initialSetup && !accountLock && otpValidation ? (
				<OtpValidation {...{ userEnteredNumber, setAccountLock, setMfaVerified, setResendOtp, setSetupPage, setOtpValidation }} />
			) : (
				<AppLoader />
			)}
		</>
	);
};

export default MfaContainer;
