import Axios from 'axios';
import { put, takeEvery, call } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import Actions from '../action';
import ClevertapReact from 'src/utils/clever-tap';

export function* addIndividualContractServices() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/CorporateSetting/${corporateId}/department`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getDepartmentDataSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.getDepartmentDataFail(err));
	}
}
export function* getContractDetailServices() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/PayrollSetting/${corporateId}/contracttype`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getContractDataSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.getContractDataFail(err));
	}
}
export function* saveContractDetailServices(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const payload = {
		flag: '2',
		joiningDate: action?.payload?.joiningDate,
		contractEndDate: action?.payload?.endDate ? action?.payload?.endDate : '',
		designation: action?.payload?.designation,
		department: action?.payload?.department,
		contractType: action?.payload?.contractName,
		basicSalary: 0,
		firstName: '',
		lastName: '',
		gender: '',
		dateofBirth: '',
		mobileNumber: '',
		nationality: '',
		email: '',
		salaryProcessingChannel: '',
		employeeId: '',
		passportNumber: '',
		emiratesId: '',
		emiratesIdExpiryDate: '',
		wpsPersonId: '',
		wpsEstablishmentId: '',
		labourCardNumber: '',
		iban: '',
		bankName: '',
	};

	try {
		const startTime = performance.now();

		const uri = `${DOMAIN_URL}/Employees/${corporateId}/individual-employee/save`;
		const res = yield call(Axios.post, uri, payload);
		
		const endTime = performance.now();
		const duration = endTime - startTime;
		ClevertapReact.event('Add_Individual_Employee_S2', {step: "Save Contract Details", duration });
		
		yield put(Actions.creators.saveContractDetailStartSuccess(res?.data));
		yield put(Actions.creators.isContractDetailSaved(res?.data ? false : true));
	} catch (err) {
		yield put(Actions.creators.saveContractDetailStartFail(err));
	}
}

export default function* addIndividualWatcher() {
	yield takeEvery(Actions.types.GET_DEPARTMENT_DATA_START, addIndividualContractServices);
	yield takeEvery(Actions.types.GET_CONTRACT_DATA_START, getContractDetailServices);
	yield takeEvery(Actions.types.SAVE_CONTRACT_DETAIL_START, saveContractDetailServices);
}
