export const images = {
	fullscreen: require('./fullscreen.png'),
	logo_sm: require('./logo_sm.png'),
	register: require('./register.png'),
	welcome: require('./welcome.png'),
	konnektivelogo: require('./konnektivelogo.png'),
	prof3: require('./prof3.png'),
	userProfileVector: require('./userProfileVector.png'),
	dashboardicon: require('./dashboardicon.png'),
	profileicon: require('./profileicon.png'),
	usercard: require('./usercard.png'),
	prof2: require('./prof2.png'),
	edit: require('./edit.png'),
	camera: require('./camera.png'),
	logout: require('./logout.png'),
	collapseleft: require('./collapseleft.png'),
	collapseright: require('./collapseleft.png'),
	deleteimg: require('./deleteimg.png'),
	business: require('./business.png'),
	mail: require('./mail.png'),
	phone: require('./phone.png'),
	web: require('./web.png'),
	user: require('./user.png'),
	lock: require('./lock.png'),
	deactiveimg: require('./deactiveimg.png'),
	signout: require('./signout.png'),
	k: require('./k.png'),
	upload: require('./upload.png'),
	inactiveempty: require('./inactiveempty.png'),
	inactivedelete: require('./inactivedelete.png'),
	commission: require('./commission.png'),
	affiliatemanagement: require('./affiliatemanagement.png'),
	MultiDevices: require('./MultiDevices.png'),
	logo: require('./logo.png'),
	payrollSimply: require('./payrollSimply.png'),
	Payroll: require('./Payroll.png'),
	ordercards: require('./ordercards.png'),
	realtime: require('./realtime.png'),
	alertsinbox: require('./alertsinbox.png'),
	ellipse27: require('./Ellipse 27.png'),
	ellipse28: require('./Ellipse 28.png'),
	ellipse29: require('./Ellipse 29.png'),
	ellipse31: require('./Ellipse 31.png'),
	ellipse32: require('./Ellipse 32.png'),
	ellipse33: require('./Ellipse 33.png'),
	ellipse34: require('./Ellipse 34.png'),
	ellipse35: require('./Ellipse 35.png'),
	ellipse36: require('./Ellipse 36.png'),
	ellipse37: require('./Ellipse 37.png'),
	ellipse38: require('./Ellipse 38.png'),
	ellipse39: require('./Ellipse 39.png'),
	ellipse82: require('./Ellipse 82.svg'),
	horizontal: require('./horizontal_line.png'),
	check: require('./Check.png'),
	error: require('./Error.png'),
	process: require('./Process.png'),
	India: require('./India.png'),
	Frame: require('./Frame.svg'),
	leftbubble: require('./leftbubble.png'),
	rightbubble: require('./rightbubble.png'),
	LabourCard: require('./LabourCard.svg'),
	LabourCard2: require('./LabourCard-II.svg'),
	EmiratesIDCard: require('./EmiratesIDCard.svg'),
	C3Pay: require('./C3Pay.svg'),
	WeTransfer: require('./WeTransfer.svg'),
	tabledownarrow: require('./tabledownarrow.svg'),
	tableuparrow: require('./tableuparrow.svg'),
	hoverarrow: require('./hoverarrow.svg'),
	wpsPersonid: require('./wps-person.svg'),
	establishmentid1: require('./establishmentid1.svg'),
	establishmentid2: require('./establishmentid2.svg'),
	arab: require('./arab.svg'),
	cluser: require('./cluser.svg'),
	park: require('./park.svg'),
	international: require('./international.svg'),
	dubai: require('./dubai-internet-city.svg'),
	enpark: require('./enpark.svg'),
	outsource: require('./outsource.svg'),
	knowledge_village_logo: require('./knowledge_village_logo.svg'),
	jafza: require('./jafza.svg'),
	daz: require('./daz.svg'),
	technopark: require('./Technopark-logo.svg'),
	Calculator: require('./Calculator.png'),
	Production: require('./Production.png'),
	Shield: require('./Shield.png'),
	Statements: require('./Statements.png'),
	Dotted_Line: require('./Dotted_Line.png'),
	chck: require('./chck.png'),
	plusIcon: require('./plusIcon.svg'),
	// plus: require('./Plus.svg'),
	hR_expired: require('./hrModule/Expired.svg'),
	hR_warning: require('./hrModule/Warning.svg'),
	hR_missing: require('./hrModule/Missing.svg'),
	hR_upToDate: require('./hrModule/UpToDate.svg'),
	hR_excel: require('./hrModule/Excell.svg'),
	hR_pdf: require('./hrModule/PDF.svg'),
	hR_uncheck: require('./hrModule/UnCheck.svg'),
	hR_check: require('./hrModule/Check.svg'),
	hR_download: require('./hrModule/Download.svg'),
	hR_search: require('./hrModule/Search.svg'),

	edenredSVG: require('./edenredSVG.svg'),

	// dubai: require('./dubai.svg'),
	Leave_Approve_Empty: require('./Weather.svg'),
	Annualleave: require('./AnnualLeave.svg'),
	Check: require('./Check.svg'),
	Leave_Warning: require('./Warning.svg'),
	Leave_help: require('./Help.svg'),
	Leave_vector: require('./Vector.svg'),
	Leave_Comments: require('./Comments.svg'),
	Mobile_Card_Statement: require('./MobileCardStatement.png'),
	Green_Tick: require('./GreenTick.png'),
	imgupload: require('./send-square.png'),
	success: require('./tick-square.png'),
	btn: require('./btn-icn.svg'),
	primary_disabled: require('./primary_disabled.svg'),
	heart: require('./heart.svg'),
	close: require('./close.svg'),
	navicon: require('./navicon.svg'),
	menu: require('./menu.svg'),
	pendingIcon: require('./pendingIcon.svg'),
	pending: require('./pending.svg'),
	celebration: require('./celebration.svg'),
	toasterheart: require('./toasterheart.svg'),
	edittable: require('./Edit_Table.svg'),
	blackheart: require('./blackheart.svg'),
	progresstick: require('./progresstick.svg'),
	greytick: require('./greytick.svg'),
	slider1: require('./slider1.jpg'),
	slider2: require('./slider2.jpg'),
	slider3: require('./slider3.jpg'),
	progressdanger: require('./progressdanger.svg'),
	progresspending: require('./progresspending.svg'),
	headerimg: require('./headerimg.svg'),
	down: require('./Down.svg'),
	add: require('./add.svg'),
	minus: require('./minus.svg'),
	green: require('./checkgreen.svg'),
	calc: require('./calc.svg'),
	doc: require('./doc.svg'),
	trip: require('./trip.svg'),
	msg: require('./msg.svg'),
	info: require('./Info.svg'),
	manageleave: require('./manageleave.svg'),
	automated: require('./automated.svg'),
	automatic: require('./automatic.svg'),
	sendAnnouncement: require('./sendAnnouncement.svg'),
	payrollplus: require('./payroll-plus.svg'),
	salary: require('./salary.svg'),
	manage: require('./manage.svg'),
	holder: require('./c3payholder.svg'),
	liveannouncment: require('./liveannouncment.svg'),
	documentmanagment: require('./documentmanagment.svg'),
	leavemanagment: require('./leavemanagment.svg'),
	upgradepayroll: require('./upgradepayroll.svg'),
	location: require('./Location.svg'),
	leftsarrow: require('./Leftarrow.svg'),
	wps: require('./wps.svg'),
	leftarrow: require('./arrow-left.svg'),
	calculation: require('./calculation.svg'),
	penalities: require('./penalities.svg'),
	end: require('./end.svg'),
	leavecalculation: require('./leavecalculation.svg'),
	locknew: require('./lock.svg'),
	slideCard: require('./slide-card.svg'),
	slideEmail: require('./slide-email.svg'),
	slidePad: require('./slide-pad.svg'),
	slideTime: require('./slide-time.svg'),
	inviteEmployees: require('./InviteEmployees.svg'),
	employeesTable: require('./EmployeesTable.svg'),
	assignDepartments: require('./AssignDepartments.svg'),
	assignManagerPermissions: require('./AssignManagerPermissions.svg'),

	edTick: require('./ed-tick.svg'),
	unempArrow: require('./unemp-arrow.svg'),
	unemploymentGif: require('./unemployment.gif'),
	expiredImg: require('./expired-img.svg'),
	edTickRed: require('./ed-tick-red.svg'),
	infoCircle: require('./info-circle.svg'),

	// MFA
	mfaCarouselFirst: require('./carousel-first-bulk-subscribe.png'),
	mfaCarouselSecond: require('./carousel-second-replace-card.png'),

	// OTP
	verifySuccessTick: require('./verify-success-tick.png')

};
