import Axios from 'axios';
import { put, takeEvery, call } from 'redux-saga/effects';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import Actions from '../action';
import ClevertapReact from 'src/utils/clever-tap';

export function* getNationalityServices() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/Employees/${corporateId}/countries`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getNationalityDataSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.getNationalityDataFail(err));
	}
}
export function* addIndividualServices(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const payload = {
		flag: '1',
		firstName: action?.payload?.firstName,
		lastName: action?.payload?.lastName,
		gender: action?.payload?.gender,
		dateofBirth: action?.payload?.dob,
		mobileNumber: action?.payload?.mobile,
		nationality: action?.payload?.nationality,
		email: action?.payload?.email,
		salaryProcessingChannel: action?.payload?.account,
		employeeId: '',
		passportNumber: '',
		emiratesId: '',
		emiratesIdExpiryDate: '',
		wpsPersonId: '',
		wpsEstablishmentId: '',
		labourCardNumber: '',
		iban: '',
		bankName: '',
		joiningDate: '',
		department: '',
		designation: '',
		basicSalary: 0,
		contractType: '',
		contractEndDate: '',
	};

	try {
		const startTime = performance.now();
		
		const uri = `${DOMAIN_URL}/Employees/${corporateId}/individual-employee/save`;
		const res = yield call(Axios.post, uri, payload);
		
		const endTime = performance.now();
		const duration = endTime - startTime;
		ClevertapReact.event('Add_Individual_Employee_S1', {step: "Save Personal Details", duration });
		
		yield put(Actions.creators.saveIndividualEmployeeSuccess(res?.data));
		yield put(Actions.creators.isPersonalDetailSaved(res?.data ? false : true));
	} catch (err) {
		yield put(Actions.creators.saveIndividualEmployeeFail(err));
	}
}
export default function* addIndividualWatcher() {
	yield takeEvery(Actions.types.SAVE_INDIVIDUAL_EMPLOYEE_START, addIndividualServices);
	yield takeEvery(Actions.types.GET_NATIONALITY_DATA_START, getNationalityServices);
}
