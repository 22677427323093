import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import mfaActions from '../mfaActions';
import { MFA_CONSTANTS } from '../constants';
import FlagImage from '@sharedComponent/FlagImage';
import { UserService } from 'src/services';
import { getLocalvariable, setLocalvariable } from '@helpers';
import CountryDropdownInput from '@sharedComponent/CountryDropdownInput';

const SetUpMfa = ({ setUserEnteredNumber }) => {
	const [phoneNumber, setPhoneNumber] = useState('');
	const [stdCode, setStdCode] = useState('971');
	const [validation, setValidation] = useState(false);
	// Default country as UAE
	const [country, setCountry] = useState({
		code: 'ae',
		stdCode: '971',
	});
	const [countries, setCountries] = useState([]);
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);

	useEffect(() => {
		const countriesDataFromLocal = getLocalvariable(MFA_CONSTANTS.localStorage.countriesKey);
		if (countriesDataFromLocal) {
			const parsedCountriesData = typeof countriesDataFromLocal === 'string' ? 
				JSON.parse(countriesDataFromLocal) : [];
			setCountries(parsedCountriesData);
		} else {
			getCountries();
		}
	}, []);

	const getCountries = async () => {
		const userService = new UserService();
		try {
			const res = await userService.getAllCountries();
			setCountries(res.data);
			setLocalvariable(MFA_CONSTANTS.localStorage.countriesKey, res.data);
		} catch (error) {
			console.error(error);
		}
	};

	const sendingOTP = () => {
		if (!phoneNumber.length) {
			setValidation(true);
			return;
		}
		const phoneNumberFormat = `+${stdCode}${phoneNumber}`;
		const body = {
			phoneNumber: phoneNumberFormat,
			reason: MFA_CONSTANTS.otpReason.mfa,
		};
		dispatch(mfaActions.creators.sendOtp(body));
		setUserEnteredNumber(phoneNumberFormat);
		setValidation(false);
	};
	const handleChange = (e) => {
		const { shortCode, stdCode } = e || {};
		if (stdCode) {
			setStdCode(stdCode);
		}
		setCountry({ code: shortCode, stdCode });
	};
	const inputChange = (value) => {
		setPhoneNumber(value);
		setValidation(false);
	};

	return (
		<div className="setup-mfa-container">
			<div className="setup-mfa-text">
				<div className="setup-mfa-title">
					<ETypo h3 bold>
						Set up Multi-Factor
					</ETypo>{' '}
					<br />
					<ETypo h3 bold>
						Authentication
					</ETypo>
				</div>
				<div className="setup-mfa-description">
					<ETypo className="setup-mfa-description-text" b2 medium>
						To verify your identity, please enter your mobile number.
					</ETypo>
					<br />
					<ETypo className="setup-mfa-description-text" b2 medium>
						The number entered will be saved for 2-step verification.
					</ETypo>
				</div>
			</div>
			<div className="setup-mfa-number-field-wrapper">
				<div className="setup-mfa-number-field">
					<CountryDropdownInput
						options={countries}
						value={stdCode}
						inputValue={phoneNumber}
						country={country}
						onChange={handleChange}
						onInputChange={inputChange}
						placeholder="Enter your mobile number"
						label="Mobile Number"
						searchLabel="countryName"
						searchHolder="Search by country name"
						prefix={<FlagImage flagCode={country.code} />}
						optionValue="stdCode"
						isError={validation}
					/>
					{validation && (
						<ETypo b3 className="error-msg">
							Invalid Number
						</ETypo>
					)}
				</div>
				<div className="setup-mfa-otp-button-container" style={{ display: 'flex', justifyContent: 'end' }}>
					<EdenredButtons
						loading={auth?.otp?.loading}
						className="setup-mfa-otp-button"
						style={{ maxWidth: '103px' }}
						children="Send OTP"
						onClick={sendingOTP}
					/>
				</div>
			</div>
		</div>
	);
};

export default SetUpMfa;
