import React, { useState } from 'react';
import EdenredCarousel from '@pages/DesignSystem/carousel/EdenredCarousel';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import { images } from 'src/assets/img';
import './mfaSlider.scss';
import ImageComponent from '@sharedComponent/image-component';

const MfaImageCarousel = () => {
	const [count, setCount] = useState(1);
	const length = carouselImage?.length;
	const handleButton = (value) => {
		if (value === 'prev') {
			setCount(count === 0 ? length - 1 : count - 1);
		} else {
			setCount(count === length - 1 ? 0 : count + 1);
		}
	};
	const carouselImage = [
		{
			id: 0,
			image: images?.mfaCarouselFirst,
		},
		{
			id: 1,
			image: images?.mfaCarouselSecond,
		},
	];

	return (
		<div className="image_slider_main">
			<EdenredCarousel
				pagination={count}
				autoplay
				duration={10000}
				showPagination={false}
				defaultIndicator={true}
				className="slider-container"
				prevButton={
					<div className="left-img" onClick={() => handleButton('prev')}>
						<ImageComponent src={not_focus_icons?.leftArrow} />
					</div>
				}
				nextButton={
					<div className="right-img" onClick={() => handleButton('next')}>
						<ImageComponent src={not_focus_icons?.rightArrow} />
					</div>
				}>
				{carouselImage?.map((data, index) => (
					<div key={index} className="image_slider">
						<div className="main_carousel">
							<ImageComponent src={data?.image} className="mfa-img" />
						</div>
					</div>
				))}
			</EdenredCarousel>
		</div>
	);
};

export default MfaImageCarousel;
